.App {
  font-family: 'VT323', monospace;
  text-align: center;
  background-color:#1e1e1e;
  color: #03A062;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
header {
  display: none;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #03A062;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
nav {
  display: none;
  background-color: #1e1e1e;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.navItems {
  padding: 8px;
  background-color: #1e1e1e;
  width: 100%;
  max-width: 340px;
}
.navPromptWrapper {
  border-bottom: 1px solid #ffffff33;
  padding: 10px;
  height: 55vh;
  overflow-y: auto;
}
.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  top: -45px;
}
pre {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}
.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #1e1e1e;
}
.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}
.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.chatBox {
  flex: 1;
  background-color: #1e1e1e;
  position: relative;
  line-height: 24px;
  color: #03A062;
  font-size: 15px;
  
}
#introsection::before,
#introsection::after {
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
}
#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
  
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #1e1e1e;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #1e1e1e;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  background-color: #1e1e1e;
}
.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  padding: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  padding-top: 20px;
}
.inputPrompttTextarea {
float: left;
  width: 100%;
  margin-left: 0;
  max-height: 200px;
  height: 120px;
  padding: 0px;
  
  font-size: 16px;
  background-color: #41414e;
  color: #03A062;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  
  white-space: pre-wrap; 
  overflow-wrap: break-word;

}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
    overflow: hidden;


  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
    /* overflow: hidden; */
    
  }
  header {
    display: flex;
    align-items: center;
    background: #1e1e1e;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
    /* overflow: hidden; */

  }
  nav {
    display: flex;
    /* overflow: hidden; */

  }
  .sideMenu {
    display: none;
    /* overflow: hidden; */

  }
  .chatBox {
    position: static;
    /* overflow: hidden; */

    
  }
  .chatPromptWrapper {
    padding: 12px;
    overflow: hidden;

  }
  .botMessageWrapper {
    padding: 12px;
    overflow: hidden;

  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 14px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

.micPosition{
  padding: 4px;
  background-color: #444654;
  color: #03A062;
  border: 0;
  transform: translate(700px, 1px) 


}
@media only screen and (max-width: 767px) {
  .recording-button {
    display: inline;
    position: absolute;
    top: 30px;
    right: -80px;
       
  }
}

@media only screen and (min-width: 768px) {
  .recording-button {
    display: inline;
    position: absolute;
    top: 20px;
    right: 140px;
       


  }
}


@media only screen and (max-width: 767px) {
  .micPosition {
    padding: 4px;
    background-color: #444654;
    color: red;
    border: 0;
    transform: translate(257px, -6px);
  }
  .chatLogWrapper {
    height: 72vh;
    overflow-y: auto;
  
    
  }
  
}

